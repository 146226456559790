import * as React from "react";
import { Typography, Button, Container } from "@mui/material";
import { useProductDetail } from "../../../context/ProductDetailsContext";
import Grid from "@mui/material/Grid";
import "./ProductSwitcher.css";

const ProductSwitcher = () => {
  const { subTotal, productSelected, handleChangeProductSelected, itemObject } =
    useProductDetail();
  function SwitcherButton({ text, period }) {
    return (
      <Button
        className={
          productSelected === period
            ? "YardProductInformation-period-selected"
            : "YardProductInformation-period-not-selected"
        }
        onClick={(e) => handleChangeProductSelected(period)}
      >
        {text}
      </Button>
    );
  }
  return (
    <Container className="YardProductInformation-product-switcher-container">
      <Typography className="YardProductInformation-switcher-title">
        Complete Subscription Details
      </Typography>
      <Grid container className="YardProductInformation-switcher-container">
        {itemObject.ItemType !== "Chassis" ? (
          <>
            <Grid item xs={6} display={"flex"}>
              <SwitcherButton text="Daily" period="Daily" />
            </Grid>
            <Grid item xs={6} display={"flex"}>
              <SwitcherButton text="Monthly" period="Monthly" />
            </Grid>
          </>
        ) : (
          <>
            {" "}
            <Grid item xs={12} display={"flex"}>
              <SwitcherButton text="Week" period="Weekly" />
            </Grid>
          </>
        )}
      </Grid>
      <div className="YardProductInformation-price-container">
        <Typography className="YardProductInformation-subscription-cost-big">
          <Typography className="YardProductInformation-dollar-sign">
            $
          </Typography>
          {subTotal.toFixed(2).split(".")[0]}
          <Typography className="YardProductInformation-subscription-cost-cents">
            .{subTotal.toFixed(2).split(".")[1]}{" "}
            <Typography className="YardProductInformation-subscription-cost-period">
              /Day
            </Typography>
          </Typography>
        </Typography>
      </div>
    </Container>
  );
};

export default ProductSwitcher;
